import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { setChatID } from './chatIDModule';


const Disclaimer = ({ onIDGenerated, show }) => {
	const [showDisclaimer, setShowDisclaimer] = useState(true);
	const [acceptedConditions, setAcceptedConditions] = useState(false);
	const [captchaSuccess, setCaptchaSuccess] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);

	useEffect(() => {
		
		setShowDisclaimer(show);
		setAcceptedConditions(false);
		setCaptchaSuccess(false);
	}, [show]);
	useEffect(() => {
		const script = document.createElement('script');
		console.log(1)
		script.src = "https://www.google.com/recaptcha/enterprise.js?render=6Leeg28pAAAAAJAhESat6HrdHV6j1FhpmjkpyCED";
		console.log(2)
		script.onload = () => setIsRecaptchaLoaded(true); // Mise à jour de l'état une fois chargé
		document.head.appendChild(script);

		const style = document.createElement('style');
		style.textContent = `.grecaptcha-badge { visibility: hidden; }`;
		document.head.appendChild(style);
		return () => {
			// Nettoie le script et le style ajoutés lors du démontage du composant
			document.head.removeChild(script);
			document.head.removeChild(style);
		}
	  }, []);
	

	  const onClickcaptcha = async (e) => {
		if (acceptedConditions) {
		e.preventDefault();
		if (isRecaptchaLoaded) {
			setIsSubmitting(true);
			window.grecaptcha.enterprise.ready(async () => {
			const token = await window.grecaptcha.enterprise.execute('6Leeg28pAAAAAJAhESat6HrdHV6j1FhpmjkpyCED', { action: 'chat_acces' });
			console.log("Token reCAPTCHA:", token);
			
			const data = { token: token };
			console.log("Données envoyées au backend:", data);
	  
			axios.post('https://ptestbe.meridiem.be/CBMRT-PT-captcha/captcha-verify', data, {
    headers: {
        'Content-Type': 'application/json'
    }
})
.then(response => {
    console.log("Réponse du backend:", response);
    if (response.data.validation === true) {
        // Si la validation est true, récupérer ChatID
        const chatID = response.data.ChatID;

        Cookies.set('ChatID', chatID, { expires: 7, sameSite: 'None', secure: true  });
		const tempchatID = Cookies.get('ChatID');
		console.log("1",chatID)
        if (tempchatID) {
			console.log("ChatID stocké dans les cookies:", tempchatID);
			onIDGenerated(chatID);
		} else {
			// Si le cookie n'est pas correctement défini, utilise une valeur générale
			setChatID(chatID);
			console.log("2",chatID)
			console.log("Utilisation de la valeur générale pour ChatID");
		}

		// setChatID(chatID)
        onIDGenerated(chatID);
		setShowDisclaimer(false);
		setIsSubmitting(false);
    } else {
        // Gestion de l'échec de la validation
        console.error("Erreur de validation");
        alert("Erreur de validation. Veuillez retenter.");
		setIsSubmitting(false);
    }
})
.catch(error => {
    console.error("Erreur lors de la communication avec l’API:", error);
    alert("Une erreur s'est produite lors de la communication avec le serveur.");
	setIsSubmitting(false);
});
		  });
		} else {
		  console.error('reCAPTCHA n\'est pas encore chargé');
		}
	} else {
		alert('Veuillez accepter les conditions et réussir le captcha.');
	}
	  };




	return (
		<div
			className={`${
				showDisclaimer ? 'block' : 'hidden'
			} fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center`}>
			<div className='mx-auto  bg-white p-5 rounded-xl w-11/12 sm:w-[502px]  text-center shadow-xl '>
				<p className='text-xl font-semibold my-5'>Conditions d'utilisation:</p>
				<div className='text-left'>
					<p className='text-base my-4'>
					Merci de vous intéresser au MR. Nous utilisons l'IA pour clarifier notre programme et répondre efficacement à vos questions, soulignant notre engagement envers l'ère numérique et les bénéfices de l'IA pour la démocratie.					</p>
				</div>
				<div className='flex justify-center items-center my-4'>
					<label className=' '>
						<input
							type='checkbox'
							checked={acceptedConditions}
							onChange={() => setAcceptedConditions(!acceptedConditions)}
							className='w-5 h-5 m-2  rounded-sm accent-blue-500  forced-colors:appearance-auto '
						/>
					</label>
					<div className='text-xs text-left'>
						Je suis d’accord avec les conditions générales d’utilisation
						<br />
						<a href='https://www.mr.be/mentions-legales/'
						className='text-xs text-blue-600 underline'
						target='_blank'
						rel='noopener noreferrer'>
						Conditions générales d’utilisation
						</a>
					</div>
				</div>
				<button
					onClick={onClickcaptcha}
					disabled={isSubmitting} // Désactive le bouton pendant l'appel API
					className={`${
						!acceptedConditions || isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
					} py-1 px-8 text-white rounded-lg bg-gradient-to-r from-blue-600 to-blue-300 my-3`}
					style={{ boxShadow: '2px 2px 3px rgba(0, 0, 0, 0.2)' }}>
					J'y vais!
					</button>
			</div>
		</div>
	);
};

export default Disclaimer;
